import React, { useState, useEffect } from "react";
import ReactTextTransition from "react-text-transition";

const words = [
  "Hello",
  "NiHao",
  "こんにちは",
  "¡Hola!",
  "안녕하세요",
  "Namaste!",
];

function ReactText() {
  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 700);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <React.Fragment>
      <section>
        <section className="inline">
          {words[wordIndex].split("").map((letter, i) => (
            <ReactTextTransition
              key={i}
              className="big"
              direction="down"
              inline
            >
              {letter}
            </ReactTextTransition>
          ))}
        </section>
      </section>
    </React.Fragment>
  );
}

export default ReactText;
