import React, { Component, useState, useEffect } from "react";
import ReactText from "./ReactText";

class Preloader extends Component {
  state = {
    showPreloader: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showPreloader: false });
    }, 4200);
  }

  render() {
    if (this.state.showPreloader) {
      return (
        <div className="preloader">
          <h2>
            <ReactText />
          </h2>
        </div>
      );
    }

    return this.props.children;
  }
}

export default Preloader;
