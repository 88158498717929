import React, { useState } from "react";
import "../index.css";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Container,
  Grid,
  Hidden,
  Icon,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import * as Icons from "@mui/icons-material";
import { TypeAnimation } from "react-type-animation";

import SrsK from "../images/SrsK.png";
import Saras from "../images/saras.png";
import Preloader from "./Preloader";

const Home = () => {
  return (
    <div>
      <Preloader>
        <div className="App"></div>
      </Preloader>

      <Grid item container lg={12} className="navbar">
        <Grid item className="navbar-logo">
          <img src={SrsK} />
        </Grid>
        <Grid item className="social-icons">
          <Grid>
            <Button
              component={Link}
              target="_blank"
              to="https://www.instagram.com/srs_karanjit/"
              variant=""
              color="primary"
            >
              <Icon component={Icons.Instagram} />
            </Button>

            <Button
              component={Link}
              target="_blank"
              to="https://www.linkedin.com/in/saraskaranjit/"
              variant=""
              color="primary"
            >
              <Icon component={Icons.LinkedIn} />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid item lg={12} className="website-announce">
        <span>
          <span className="gradient-text">Website</span>{" "}
          <TypeAnimation
            sequence={["Under Construction..", 1000, "Coming Soon!", 1000]}
            repeat={Infinity}
          />
        </span>
      </Grid> */}

      <Grid item lg={12} container className="main-content">
        <Grid item lg={5} className="main-content-left">
          <img src={Saras} height={800} />
        </Grid>
        <Grid item lg={7} className="main-content-right">
          <h1 className="gradient-text">I design products</h1>
          <h1>that delight and</h1>
          <h1>
            inspire people<span className="red-dot">.</span>
          </h1>
          <p>
            Hi! I’m Saras, a Designer based in Kathmandu
            <span className="red-dot">.</span> I focus on solving problems
            through designs<span className="red-dot">.</span>
          </p>
          <Button
            href="mailto:skaranjit24@gmail.com"
            className="cta-button-1"
            variant="contained"
          >
            Let's Catch Up!
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
